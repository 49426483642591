import { BaseService, requestWrapper, TRequestParamsId } from "shared"
import { AxiosResponse } from "axios"

import { IVideoStream, IVideoStreamList, IVideoStreamListParams, IVideoStreamUpdate } from "@types"

class VideoStreamService extends BaseService {
  getVideoStream = ({ params }: { params: { equipment: TRequestParamsId } }): Promise<AxiosResponse<IVideoStream>> => {
    return this.api({ method: "GET", url: `/video/stream/`, params })
  }

  getVideoStreamList = ({ params }: { params: IVideoStreamListParams }): Promise<AxiosResponse<IVideoStreamList>> => {
    return this.api({ method: "GET", url: `/video/stream/`, params })
  }

  putVideoStream = ({
    params: { id },
    payload,
  }: {
    params: { id: TRequestParamsId }
    payload: IVideoStreamUpdate
  }): Promise<AxiosResponse<IVideoStream>> => {
    return this.api({
      method: "PUT",
      url: `/video/stream/${id}/`,
      data: payload,
      transformRequest: [
        function (data, headers): string {
          if (headers) headers["Content-Type"] = "application/json"
          return JSON.stringify(data)
        },
      ],
    })
  }

  postVideoStream = ({ data }: { data: IVideoStreamUpdate }): Promise<AxiosResponse<IVideoStream>> => {
    return this.api({
      method: "POST",
      url: `/video/stream/`,
      data: data,
      transformRequest: [
        function (data, headers): string {
          if (headers) headers["Content-Type"] = "application/json"
          return JSON.stringify(data)
        },
      ],
    })
  }
}

const videoStream = new VideoStreamService()

export const videoStreamAPI = {
  getVideoStream: requestWrapper<IVideoStream, typeof videoStream.getVideoStream>(videoStream.getVideoStream),
  getVideoStreamList: requestWrapper<IVideoStreamList, typeof videoStream.getVideoStreamList>(
    videoStream.getVideoStreamList
  ),
  putVideoStream: requestWrapper<IVideoStream, typeof videoStream.putVideoStream>(videoStream.putVideoStream),
  postVideoStream: requestWrapper<IVideoStream, typeof videoStream.postVideoStream>(videoStream.postVideoStream),
}
