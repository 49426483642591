import { AxiosResponse } from "axios"
import { BaseService, TRequestParamsId, requestWrapper } from "shared"

import {
  IRecommendation,
  IRecommendationList,
  IEditingRecommendationProps,
  IEchoRecommendation,
  IEchoRecommendationList,
  TMultipleEditingRecommendation,
  IModbusRecommendation,
  IOPCRecommendation,
  ERecommendationType,
  IHeuristicVA,
} from "@types"

export interface IGetLastRecommendationParams {
  start_time?: string
  end_time?: string
  equipment?: string | number
  visible?: boolean
  type?: ERecommendationType
}

interface IPatchRec {
  params: { id: TRequestParamsId }
  payload: IEditingRecommendationProps
}

class RecService extends BaseService {
  getRecList = ({
    params: { reqString },
  }: {
    params: { reqString: string }
  }): Promise<AxiosResponse<IRecommendationList>> => {
    return this.api.get<IRecommendationList>(`/recommendations/common/${reqString}`)
  }
  getLastRec = ({ params }: { params?: IGetLastRecommendationParams }): Promise<AxiosResponse<IRecommendation>> => {
    return this.api({ method: "GET", url: `/recommendations/common/last/`, params })
  }

  getEchoRecList = (params = ""): Promise<AxiosResponse<IEchoRecommendationList>> => {
    return this.api.get<IEchoRecommendationList>(`/recommendations/module/${params}`)
  }
  getLastEchoRec = ({
    params: { reqString },
  }: {
    params: { reqString: string }
  }): Promise<AxiosResponse<IEchoRecommendation>> => {
    return this.api.get<IEchoRecommendation>(`/recommendations/module/last/${reqString}`)
  }

  patchMultipleRecs = ({
    payload,
  }: {
    payload: TMultipleEditingRecommendation
  }): Promise<AxiosResponse<IRecommendation[]>> => {
    return this.api({
      method: "patch",
      url: `/recommendations/common/multiple/`,
      data: payload,
      transformRequest: [
        function (data, headers): any {
          if (headers) headers["Content-Type"] = "application/json"
          return JSON.stringify(data)
        },
      ],
    })
  }
  patchEchoRec = ({ params: { id }, payload }: IPatchRec): Promise<AxiosResponse<IEchoRecommendation>> => {
    return this.api.patch(`/recommendations/module/${id}/`, payload)
  }
  patchModbusRec = ({ params: { id }, payload }: IPatchRec): Promise<AxiosResponse<IModbusRecommendation>> => {
    return this.api.patch(`/recommendations/modbus/${id}/`, payload)
  }
  patchOPCRec = ({ params: { id }, payload }: IPatchRec): Promise<AxiosResponse<IOPCRecommendation>> => {
    return this.api.patch(`/recommendations/opc/${id}/`, payload)
  }

  getHeuristicVAData = ({ params: { id } }: { params: { id: number } }): Promise<AxiosResponse<IHeuristicVA>> => {
    return this.api.get(`/recommendations/module/heuristic_vibration_analysis/${id}/`)
  }
}

const recService = new RecService()

export const recsAPI = {
  getRecList: requestWrapper<IRecommendationList, typeof recService.getRecList>(recService.getRecList),
  getLastRec: requestWrapper<IRecommendation, typeof recService.getLastRec>(recService.getLastRec),

  getEchoRecList: requestWrapper<IEchoRecommendationList, typeof recService.getEchoRecList>(recService.getEchoRecList),
  getLastEchoRec: requestWrapper<IEchoRecommendation, typeof recService.getLastEchoRec>(recService.getLastEchoRec),

  patchMultipleRecs: requestWrapper<IRecommendation[], typeof recService.patchMultipleRecs>(
    recService.patchMultipleRecs
  ),
  patchEchoRec: requestWrapper<IEchoRecommendation, typeof recService.patchEchoRec>(recService.patchEchoRec),
  patchModbusRec: requestWrapper<IModbusRecommendation, typeof recService.patchModbusRec>(recService.patchModbusRec),
  patchOPCRec: requestWrapper<IOPCRecommendation, typeof recService.patchOPCRec>(recService.patchOPCRec),

  getHeuristicVAData: requestWrapper<IHeuristicVA, typeof recService.getHeuristicVAData>(recService.getHeuristicVAData),
}
