import {
  BaseService,
  IExternalNodeThreshold,
  IReceivedParams,
  IVibrationThreshold,
  IVibrationThresholdList,
  requestWrapper,
  TRequestParamsId,
} from "shared"
import { AxiosResponse } from "axios"

class ThresholdService extends BaseService {
  getExternalNodeThresholdList = (): Promise<AxiosResponse<IExternalNodeThreshold[]>> => {
    return this.api.get("/external/node/threshold/")
  }

  getVibrationThresholdList = ({
    params: { reqString },
  }: IReceivedParams<{ reqString: string }>): Promise<AxiosResponse<IVibrationThresholdList>> => {
    return this.api.get(`/modules/vibration/threshold/${reqString}`)
  }

  getVibrationThreshold = ({
    params: { moduleId },
  }: IReceivedParams<{ moduleId: TRequestParamsId }>): Promise<AxiosResponse<IVibrationThreshold>> => {
    return this.api.get(`/modules/vibration/threshold/${moduleId}`)
  }

  getVibrationAnalysisThresholdList = ({
    params: { reqString },
  }: IReceivedParams<{ reqString: string }>): Promise<AxiosResponse<IVibrationThresholdList>> => {
    return this.api.get(`/modules/vibration_analysis/threshold/${reqString}`)
  }

  getVibrationAnalysisThreshold = ({
    params: { moduleId },
  }: IReceivedParams<{ moduleId: TRequestParamsId }>): Promise<AxiosResponse<IVibrationThreshold>> => {
    return this.api.get(`/modules/vibration_analysis/threshold/${moduleId}`)
  }
}

const thresholdService = new ThresholdService()

export const thresholdServiceAPI = {
  getExternalNodeThresholdList: requestWrapper<
    IExternalNodeThreshold[],
    typeof thresholdService.getExternalNodeThresholdList
  >(thresholdService.getExternalNodeThresholdList),
  getVibrationThresholdList: requestWrapper<IVibrationThresholdList, typeof thresholdService.getVibrationThresholdList>(
    thresholdService.getVibrationThresholdList
  ),
  getVibrationThreshold: requestWrapper<IVibrationThreshold, typeof thresholdService.getVibrationThreshold>(
    thresholdService.getVibrationThreshold
  ),
  getVibrationAnalysisThresholdList: requestWrapper<
    IVibrationThresholdList,
    typeof thresholdService.getVibrationAnalysisThresholdList
  >(thresholdService.getVibrationAnalysisThresholdList),
  getVibrationAnalysisThreshold: requestWrapper<
    IVibrationThreshold,
    typeof thresholdService.getVibrationAnalysisThreshold
  >(thresholdService.getVibrationAnalysisThreshold),
}
